<template>
  <div v-if="currentRole === 4 || currentRole === 5" class="out-st" id="center-management">
    <v-row no-gutters class="pb-3">
      <v-col md="12">
        <div class="pa-10 pt-5">
          <h2 class="mb-4">Outstanding Report</h2>
          <v-row>
            <v-col md="3">
              <v-select
                :items="centerList"
                item-text="location"
                item-value="locationID"
                append-icon="keyboard_arrow_down"
                outlined
                dense
                hide-details
                placeholder="Center"
                v-model="chooseLocation"
                @change="changeLocation"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="justify-end">
            <v-btn
              v-if="permision.cFull"
              class="px-10"
              elevation="0"
              height="36"
              color="primary"
              @click="downloadExcel"
              :disabled="isLoading"
            >
              Download file excel
            </v-btn>
          </v-row>
          <div class="d-flex justify-space-between align-center pt-2 pb-2">
            <v-col md="4" class="d-flex align-center">
              <span v-if="filtered.length != 1">Total: {{ filtered.length }} students</span>
              <span v-else>Total: {{ filtered.length }} student</span>
            </v-col>
            <b class="mr-5">
              Total outstanding fee:
              {{
                filtered
                  .reduce((total, student) => {
                    return (total += student.outstandingFeeFilter)
                  }, 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
            </b>
          </div>
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"
            class="my-10"
          ></v-progress-linear>
          <v-data-table
            v-if="!isLoading && hasPermision"
            :headers="hasModPermision ? headers : headers.pop()"
            :items="filtered"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            class="elevation-0 spendingTable"
            fixed-header
            height="70vh"
            id="outstandingTable"
          >
            <template v-slot:header="{ props }">
              <tr class="fixed-row-filter header-outstanding">
                <th v-for="header in props.headers" :key="header.text" class="headerOueding">
                  <div v-if="filters.hasOwnProperty(header.value)">
                    <Autocomplete
                      v-if="filters.hasOwnProperty(header.value)"
                      @setFilter="setFilter"
                      :styleBorder="'border-bottom'"
                      :keyFilter="header.value"
                      :selectedInit="filters[header.value]"
                      :listItem="groupColumnValueList(header.value)"
                    />
                  </div>
                </th>
              </tr>
            </template>
            <template v-slot:item="{ item, index }">
              <tr>
                <td>{{ itemsPerPage * (page - 1) + index + 1 }}</td>
                <td @click="viewDetailProfile(item.stuUserID)" :classID="item.classID" class="link">
                  {{ item.studentCode }}
                </td>
                <td class="link" @click="viewDetailProfile(item.stuUserID)">
                  <p :style="{ width: '100px' }">{{ item.studentName }}</p>
                </td>
                <td>{{ item.mobilePhone }}</td>
                <td>{{ item.dob | getDate }}</td>
                <td>
                  <p
                    :class="'link'"
                    @click="viewDetailClass(item.classID)"
                    :style="{ width: '100px' }"
                  >
                    {{ item.classCode }}
                  </p>
                </td>
                <td>{{ item.startDate | getDate }}</td>
                <td>{{ item.endDate | getDate }}</td>
                <td>{{ item.dueDate | getDate }}</td>
                <td>{{ item.dueDate | getMonth }}</td>
                <!-- <td class="text-center">{{ item.expectedReturnDate }}</td> -->
                <td align="right">
                  {{ item.outstandingFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                </td>
                <td align="right">
                  {{ item.studentAccountBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                </td>
                <td>
                  <p :style="{ width: '150px' }">{{ item.qc }}</p>
                </td>
                <td>
                  <p :style="{ width: '150px' }">{{ item.ec }}</p>
                </td>
                <td>
                  <div :style="{ width: '120px' }" class="d-flex justify-center">
                    <img
                      :class="'link'"
                      :style="{ marginRight: '10px' }"
                      :src="item.hasRoadmap === 'Yes' ? iconAc : iconUnAc"
                    />
                    <p
                      @click="
                        item.hasRoadmap === 'Yes' &&
                          viewRoadMap(item.studentCode.substr(0, 5), item.classID, item.stuUserID)
                      "
                      :class="`link ${item.hasRoadmap === 'Yes' ? '' : 'disible'}`"
                    >
                      View detail
                    </p>
                  </div>
                </td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.remark === '' || item.remark === null"
                        :style="{ width: '18px' }"
                        @click="openEditNote(item)"
                        src="../../../assets/images/notes (1).svg"
                        alt=""
                      />
                      <img
                        v-bind="attrs"
                        v-on="on"
                        v-else
                        :style="{ width: '18px' }"
                        @click="openEditNote(item)"
                        src="../../../assets/images/notes.svg"
                        alt=""
                      />
                    </template>
                    <span v-if="item.remark === '' || item.remark === null">Create note</span>
                    <span v-else>View note</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table
            :headers="exportedHeader"
            :items="filtered"
            :items-per-page="10000000"
            hide-default-footer
            class="elevation-0 spendingTable"
            fixed-header
            height="0vh"
            id="exported-outstanding-table"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>
                  {{ item.studentCode }}
                </td>
                <td>
                  {{ item.studentName }}
                </td>
                <td>{{ item.dob }}</td>
                <td>
                  {{ item.classCode }}
                </td>
                <td>{{ item.startDate }}</td>
                <td>{{ item.endDate }}</td>
                <td>{{ item.dueDate }}</td>
                <td>{{ item.dueDate | getMonth }}</td>
                <!-- <td class="text-center">{{ item.expectedReturnDate }}</td> -->
                <td>
                  {{ item.outstandingFee.toLocaleString() }}
                </td>
                <td>
                  {{ item.studentAccountBalance.toLocaleString() }}
                </td>
                <td>
                  <p>{{ item.qc }}</p>
                </td>
                <td>
                  <p>{{ item.ec }}</p>
                </td>
                <td>
                  {{ item.hasRoadmap }}
                </td>
                <td>
                  {{ item.remark }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <div v-show="overlay" :class="'h'">
          <div class="card-i">
            <v-card :style="{ boxShadow: 'none' }" light>
              <v-card-title class="text-h5" :style="{ fontWeight: 700 }">
                Student notes
              </v-card-title>
              <v-divider></v-divider>
              <v-textarea
                v-model="noteText"
                :v-model="noteText"
                name="input-7-1"
                label="Add lesson notes"
                :disabled="!isEdit"
                @change="noteOnChange"
                :value="noteText"
                :rules="[v => !!v || 'This field is required']"
                outlined
                dense
                height="30vh"
                class="abc mt-3 ml-5 mr-5"
                id="abcd"
              ></v-textarea>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <div :class="'mb-4'" class="btnNote">
                  <v-btn elevation="2" large @click="overlay = false">Cancel</v-btn>
                  <v-btn
                    elevation="2"
                    large
                    color="primary"
                    @click="saveNote"
                    v-if="isEdit === true"
                    :disabled="noteText === ''"
                    >Save</v-btn
                  >
                  <v-btn elevation="2" large color="primary" @click="btn_Edit" v-else>Edit</v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-footer
      color="#fff"
      class="px-10 pb-2"
      padless
      :style="
        $store.state.isActiveSidebar ? 'width: calc(100% - 100px)' : 'width: calc(100% - 258px)'
      "
      style="display: flex; justify-content: space-between; z-index: 8"
    >
      <div>
        <v-select
          outlined
          hide-details
          dense
          :value="itemsPerPage"
          :items="[20, 50, 100, 200]"
          @change="itemsPerPage = parseInt($event, 10)"
          style="height: 32px"
        ></v-select>
      </div>

      <div>
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
          elevation="0"
        ></v-pagination>
      </div>
    </v-footer>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { report_management } from '@/api/report-management.js'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import iconAc from '../../../assets/images/icon-accept.svg'
import iconUnAc from '../../../assets/images/icon-unaccept.svg'
import { getObjectPermission } from '../../../common/index'
import XLSX from 'xlsx-js-style'
import ConfigDate from '@/mixins/configDate.js'
export default {
  components: { Autocomplete },
  mixins: [ConfigDate],
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      permision: getObjectPermission(370),
      iconAc: iconAc,
      iconUnAc: iconUnAc,
      noteText: '',
      firstFilter: '',
      isEdit: false,
      overlay: false,
      isLoading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      headers: [
        {
          text: 'No',
          align: 'start',
          value: 'no',
          sortable: false,
        },
        { text: 'Student code', value: 'studentCode', sortable: false },
        { text: 'Student name', value: 'studentName', width: '200px', sortable: false },
        { text: 'Phone number', value: 'mobilePhone', sortable: false },
        { text: 'DOB', value: 'dob', sortable: false },
        { text: 'Class code', value: 'classCode', sortable: false },
        { text: 'Start date', value: 'startDate', sortable: false },
        { text: 'End date', value: 'endDate', sortable: false },
        { text: 'Due date', value: 'dueDate', sortable: false },
        { text: 'Due month', value: 'dueMonth', sortable: false },
        // { text: 'Expect return date', value: 'expectedReturnDate', sortable: false },
        { text: 'Outstanding fee', value: 'outstandingFee', sortable: false },
        {
          text: 'Balance',
          value: 'studentAccountBalance',
          sortable: false,
        },
        { text: 'QC', value: 'qc', sortable: false },
        { text: 'EC', value: 'ec', sortable: false },
        { text: 'Roadmap created', value: 'hasRoadmap', sortable: false },
        { text: 'Action', value: 'remark', sortable: false },
      ],
      studentList: [],
      roadmapList: [],
      studentIDEdit: '',
      chooseLocation: 1,
      centerList: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      totalOutstandingFee: 0,
      searchTexts: {
        studentCode: '',
        studentName: '',
        mobilePhone: '',
        dob: '',
        classCode: '',
        startDate: '',
        endDate: '',
        dueDate: '',
        dueMonth: '',
        studentAccountBalance: '',
        outstandingFee: '',
        qc: '',
        ec: '',
        tuitionFee: '',
        hasRoadmap: '',
      },
      filters: {
        studentCode: [],
        studentName: [],
        mobilePhone: [],
        dob: [],
        classCode: [],
        startDate: [],
        endDate: [],
        dueDate: [],
        // expectedReturnDate: [],
        dueMonth: [],
        ec: [],
        qc: [],
        studentAccountBalance: [],
        outstandingFee: [],
        hasRoadmap: [],
        remark: [],
      },
    }
  },
  computed: {
    filtered() {
      return this.studentList.filter(d => {
        return Object.keys(this.filters).every(f => {
          return (
            this.filters[f].length < 1 ||
            (f === 'remark'
              ? this.filters[f].reduce((value, el) => {
                  if (Object.keys(d.remark)?.length > 0 && el === 'Yes') {
                    value = true
                  }
                  if ((d.remark === '' || d.remark === null) && el === 'No') {
                    value = true
                  }
                  return value
                }, false)
              : this.filters[f].includes(d[f]))
          )
        })
      })
    },
    hasPermision() {
      return this.permision.cFull || this.permision.cRead || this.permision.cModify
    },
    hasModPermision() {
      return this.permision.cFull || this.permision.cModify
    },
    exportedHeader() {
      const index = this.headers.findIndex(el => el.value === 'mobilePhone')
      if (index !== -1) {
        const newHeaders = [...this.headers] // Create a copy of the original array
        newHeaders.splice(index, 1) // Remove the element
        return newHeaders
      }
      return this.headers
    },
  },
  created() {
    this.chooseLocation = parseInt(localStorage.getItem('currentCenter'))
    if (localStorage.getItem('outstandingLocation')) {
      this.chooseLocation = parseInt(localStorage.getItem('outstandingLocation'))
    }
    if (localStorage.getItem('outstandingFilter')) {
      this.filters = JSON.parse(localStorage.getItem('outstandingFilter'))
    }
    this.init()
  },
  
  watch: {
    totalOutstandingFee: function (newValue) {
      this.totalOutstandingFee = newValue
    },
  },
  methods: {
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('outstandingFilter', JSON.stringify(this.filters))
    },
    downloadExcel() {
      if (this.filtered.length > 0) {
        const locationName = this.centerList.find(
          el => el.locationID === this.chooseLocation
        ).location

        const table_elt = document.getElementById('exported-outstanding-table')
        const workbook = XLSX.utils.table_to_book(table_elt)

        // Access the first sheet in the workbook (assuming there's only one sheet)
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        // Define the column width for specific columns
        const columnWidths = [
          { wch: 3 },
          { wch: 10 },
          { wch: 25 },
          { wch: 13 },
          { wch: 15 },
          { wch: 13 },
          { wch: 13 },
          { wch: 13 },
          { wch: 13 },
          { wch: 13 },
          { wch: 13 },
          { wch: 30 },
          { wch: 30 },
          { wch: 5 },
          { wch: 60 },
        ]
        worksheet['!cols'] = columnWidths

        XLSX.writeFile(workbook, 'Outstanding table from ' + locationName + '.xlsm')
      }
    },
    viewDetailProfile(userId) {
      this.$router.push({
        name: 'account-management-view-account',
        params: {
          userId: userId,
        },
      })
    },
    viewDetailClass(classID) {
      this.$router.push({
        name: 'class-management-detail',
        query: { classID: classID, fromOutStanding: true },
      })
    },
    changeInputFilter(searchText, header) {
      this.searchTexts[header] = searchText
    },
    viewRoadMap(studentID, classID, userID) {
      this.$router.push({
        name: 'student-roadmap-overview',
        query: {
          studentID: studentID,
          classID: classID,
          userID: userID,
        },
      })
    },
    async init() {
      this.isLoading = true
      await report_management.getOutstandingList(this.chooseLocation).then(res => {
        if (res) {
          res.forEach(x => {
            this.totalOutstandingFee += x.outstandingFee
            this.studentList.push(this.formatStudentInfo(x))
          })
        }
      })
      this.isLoading = false
    },
    async changeLocation(locationID) {
      this.totalOutstandingFee = 0
      localStorage.setItem('outstandingLocation', locationID)
      ;(this.filters = {
        studentCode: [],
        studentName: [],
        mobilePhone: [],
        dob: [],
        classCode: [],
        startDate: [],
        endDate: [],
        dueDate: [],
        dueMonth: [],
        ec: [],
        qc: [],
        studentAccountBalance: [],
        outstandingFee: [],
        hasRoadmap: [],
        remark: [],
      }),
        (this.isLoading = true)
      await report_management.getOutstandingList(locationID).then(res => {
        if (res) {
          this.studentList = []
          this.location = res.location
          res.forEach(x => {
            this.totalOutstandingFee += x.outstandingFee
            this.studentList.push(this.formatStudentInfo(x))
          })
        }
      })
      this.isLoading = false
    },

    formatStudentInfo(student) {
      return {
        ...student,
        // dob: dayjs(student.dob).format('DD/MM/YYYY'),
        // startDate: dayjs(student.startDate).format('DD/MM/YYYY'),
        // endDate: dayjs(student.endDate).format('DD/MM/YYYY'),
        // dueDate: dayjs(student.dueDate).format('DD/MM/YYYY'),
        dueMonth: dayjs(student.dueDate).format('MM/YYYY'),
        expectedReturnDate: student.expectedReturnDate
          ? dayjs(student.expectedReturnDate).format('DD/MM/YYYY')
          : '-',
        outstandingFeeFilter: student.outstandingFee,
        studentAccountBalance: student.studentAccountBalance
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        outstandingFee: student.outstandingFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      }
    },
    groupColumnValueList(val) {
      const keysWithNonEmptyArrays = Object.keys(this.filters).filter(
        key => this.filters[key].length > 0
      )
      if (keysWithNonEmptyArrays.length === 1) this.firstFilter = keysWithNonEmptyArrays[0]
      return val === 'remark'
        ? ['Yes', 'No']
        : this.firstFilter === val
        ? this.studentList.map(d => d[val]).filter(y => y)
        : this.firstFilter
        ? this.studentList
            .filter(el => this.filters[this.firstFilter].includes(el[this.firstFilter]))
            .map(d => d[val])
            .filter(y => y)
        : this.studentList.map(d => d[val]).filter(y => y)
      //     : this.studentList
      //         .filter(el => this.filters[firstFilter].includes(el[firstFilter]))
      // .map(d => d[val])
      // .filter(y => y)
    },
    // take note
    async getOutstandingList() {
      await report_management.getOutstandingList(this.chooseLocation).then(res => {
        if (res) {
          this.studentList = res
        }
      })
    },
    async reload() {
      this.isLoading = true
      this.overlay = false
      await this.getOutstandingList()
      this.isLoading = false
    },
    noteOnChange(value) {
      this.noteText = value
    },
    saveNote() {
      report_management.outstandingUpdateNote(this.studentIDEdit, encodeURIComponent(this.noteText))
      this.overlay = false
      this.studentList = []
      this.totalOutstandingFee = 0
      this.init()
    },

    btn_Edit() {
      this.isEdit = true
    },

    openEditNote(item) {
      if (item.remark == null || item.remark == '') this.isEdit = true
      else this.isEdit = false
      this.overlay = true
      this.studentIDEdit = item.studentCode.substr(0, 5)
      this.noteText = item.remark
    },
    // end take note
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.h {
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  z-index: 1000;
  left: 0;
}
.card-i {
  width: 500px;
  position: absolute;
  top: 50%;

  left: 50%;
  transform: translate(-50%, -50%);
}
.btnNote {
  float: right;
  margin-top: 20px;
}
.btnNote button {
  margin-left: 20px;
  min-width: 150px !important;
}

/* end take note */
.spendingTable tbody tr:nth-child(odd) {
  background-color: #f3faff;
}
.link {
  cursor: pointer;

  color: #427df2 !important;
}
.link:hover {
  text-decoration: underline;
  font-weight: 700;
}
/* roadmap */
.disible {
  pointer-events: none;
  color: #0c2e68;

  opacity: 0.4;
}
/* end roadmap */
.spanYes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  color: #deffec;
  width: 106px;
  height: 25px;
  color: #039f45;
  background: #deffec;
  border-radius: 4px;
}
.spanNo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  width: 106px;
  height: 25px;
  background: #fff6dd;
  border-radius: 4px;
  color: #fa8c06;
}

#outstandingTable tbody tr:nth-of-type(even) td {
  background-color: #f3faff !important;
}
#outstandingTable tbody tr:nth-of-type(even):hover td {
  background-color: #dbecf8 !important;
}
#outstandingTable tbody tr:nth-of-type(odd):hover td {
  background-color: #d7dbdd !important;
}
#outstandingTable tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}

.header-outstanding th:nth-child(1) {
  z-index: 5;
  span {
    width: 20px;
  }
  position: sticky;
  left: 0px;
  background: #fff;
}
.header-outstanding th:nth-child(2) {
  z-index: 5;
  span {
    z-index: 5;
    width: 70px;
  }
  position: sticky;
  left: 49px;
  background: #fff;
}
.header-outstanding th:nth-child(3) {
  z-index: 5;
  span {
    z-index: 5;
    width: 250px;
  }
  position: sticky;
  left: 140px;
  background: #fff;
}

#outstandingTable tbody tr td:nth-of-type(1) {
  p {
    width: 20px;
  }
  position: sticky;
  left: 0px;
  z-index: 0;
}

#outstandingTable tbody tr td:nth-of-type(2) {
  p {
    width: 70px;
  }
  position: sticky;
  left: 49px;
  z-index: 0;
}

#outstandingTable tbody tr td:nth-of-type(3) {
  p {
    width: 170px;
  }
  position: sticky;
  left: 140px;
  z-index: 0;
  white-space: nowrap;
}
</style>
